import { Wrapper } from "./styles"

interface InputErrorProps {
  children: React.ReactNode
}

const InputError: React.FC<InputErrorProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default InputError
