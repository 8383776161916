import { Wrapper } from './styles'

const Spinner = () => {
  return (
    <Wrapper>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  )
}

export default Spinner
