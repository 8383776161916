import { Auth } from 'aws-amplify'
import { isPremium } from '../helpers/UserHelper'
import { ConfirmSignupResponse, SigninResponse, SignupResponse, User, UserCustomAttributes } from '../interfaces'
import { ConfirmSignupResponseStatus, SigninResponseStatus, SignupResponseStatus } from '../types'

export const useSignup = (): ((email: string, password: string) => Promise<SignupResponse>) => {
  const signup = async (email: string, password: string) => {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      })

      return { status: 'SUCCESS' as SignupResponseStatus }
    } catch (e: any) {
      console.log('Error creating user', e)

      if (e?.code === 'UsernameExistsException') {
        return { status: 'EMAIL_ALREADY_EXISTS' as SignupResponseStatus }
      }

      return { status: 'ERROR' as SignupResponseStatus }
    }
  }

  return signup
}

export const useSignin = (): ((email: string, password: string) => Promise<SigninResponse>) => {
  const signIn = async (email: string, password: string) => {
    try {
      await Auth.signIn(email, password)
      return { status: 'SUCCESS' as SigninResponseStatus }
    } catch (e: any) {
      console.log('Error authenticating user', e)
      if (e?.code === 'UserNotConfirmedException') {
        return { status: 'USER_NOT_CONFIRMED' as SigninResponseStatus }
      }
      return { status: 'ERROR' as SigninResponseStatus }
    }
  }

  return signIn
}

export const useConfirmSignup = (): ((email: string, code: string) => Promise<ConfirmSignupResponse>) => {
  const confirmSignup = async (email: string, code: string) => {
    try {
      await Auth.confirmSignUp(email, code)
      return { status: 'SUCCESS' as ConfirmSignupResponseStatus }
    } catch (e: any) {
      console.log('Error confirming signup', e)
      if (e?.code === 'CodeMismatchException') {
        return { status: 'CODE_MISMATCH' as ConfirmSignupResponseStatus }
      }
      return { status: 'ERROR' as ConfirmSignupResponseStatus }
    }
  }

  return confirmSignup
}

export const useSignout = (): (() => Promise<Boolean>) => {
  const signout = async () => {
    try {
      await Auth.signOut()
      return true
    } catch (e) {
      console.log('Error signing out', e)
      return false
    }
  }

  return signout
}

export const useForgotPassword = (): ((email: string) => Promise<Boolean>) => {
  const forgotPassword = async (email: string) => {
    try {
      await Auth.forgotPassword(email)
      return true
    } catch (e) {
      console.log('Error send forgot password code', e)
      return false
    }
  }

  return forgotPassword
}

export const useForgotPasswordSubmit = (): ((email: string, code: string, newPassword: string) => Promise<Boolean>) => {
  const forgotPasswordSubmit = async (email: string, code: string, newPassword: string) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword)
      return true
    } catch (e) {
      console.log('Error changing password', e)
      return false
    }
  }

  return forgotPasswordSubmit
}

export const useUpdateUserAttributes = (): ((
  user: any,
  userCustomAttributes: UserCustomAttributes
) => Promise<Boolean>) => {
  const updateUserAttributes = async (user: any, userCustomAttributes: UserCustomAttributes) => {
    try {
      const { name, rate, suburb, latitude, longitude, phone, website } = userCustomAttributes
      // name is always passed
      const attributes: any = { 'custom:name': name }

      // checking other attributes
      if (rate) {
        attributes['custom:rate'] = rate
      }
      if (suburb) {
        attributes['custom:suburb'] = suburb
      }
      if (latitude) {
        attributes['custom:latitude'] = latitude + '' // TODO fix type in Cognito
      }
      if (longitude) {
        attributes['custom:longitude'] = longitude + '' // TODO fix type in Cognito
      }
      if (isPremium(user) && phone) {
        attributes['custom:phone'] = phone // TODO remove passing ' ' ?
      }
      if (isPremium(user) && website) {
        attributes['custom:website'] = website // TODO remove passing ' ' ?
      }

      const result = await Auth.updateUserAttributes(user, attributes)
      return result === 'SUCCESS'
    } catch (e) {
      console.log('Error updating user attributes', e)
      return false
    }
  }
  return updateUserAttributes
}

export const useCurrentAuthenticatedUser = (): (() => Promise<User>) => {
  const currentAuthenticatedUser = async () => {
    if (process.env.REACT_APP_USER) {
      // @ts-ignore
      // adding `mocked` to skip methods from account page
      return Promise.resolve({
        ...JSON.parse(process.env.REACT_APP_USER),
        mocked: true,
      })
    }

    try {
      return await Auth.currentAuthenticatedUser()
    } catch (e) {
      throw e
    }
  }

  return currentAuthenticatedUser
}
