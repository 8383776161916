import { Wrapper } from "./styles"

interface ButtonWrapperProps {
  children: React.ReactNode
  marginTop?: string
}

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({children, marginTop}) => {
  return <Wrapper marginTop={marginTop}>{children}</Wrapper>
}

export default ButtonWrapper
