import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

interface WrapperProps {
  open: boolean
}
export const Wrapper = styled.nav<WrapperProps>`
  background: var(--blue);
  padding: 40px;
  /* menu width */
  transform: translateX(100vw);
  transition: all 300ms ease-out;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ open }) =>
    open &&
    `
    transform: translateX(0);
  `}
`
export const Container = styled.div``
export const CloseIcon = styled.svg`
  display: block;
  width: fit-content;
  margin-left: auto;
  fill: white;
  cursor: pointer;
`
export const menuItemStyles = `
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  width: fit-content;

  &:not(:first-child) {
    margin-top: 40px;
  }

  & > svg {
    margin-right: 14px;
  }
`
export const NavMenuItem = styled(NavLink)`
  ${menuItemStyles}
`
export const MenuItem = styled.div`
  ${menuItemStyles}
`
export const Copy = styled.div`
  color: white;
  font-size: 13px;
`
