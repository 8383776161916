import { forwardRef } from 'react'
import { Wrapper } from './styles'

interface TextInputProps {
  value: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  name?: string
  type?: string
  id?: string
  autofocus?: boolean
  autocomplete?: string
  placeholder?: string
  maxlength?: number
}

const TextInput: React.FC<TextInputProps> = forwardRef(
  (
    { name, value, onChange, type = 'text', id, autofocus, autocomplete, placeholder, maxlength },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Wrapper
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        id={id}
        autoFocus={autofocus}
        autoComplete={autocomplete}
        placeholder={placeholder}
        maxLength={maxlength}
      />
    )
  }
)

export default TextInput
