import { ShareIcon, Wrapper } from "./styles"

const InstallPwaIphoneSafariToast = () => {
  return (
    <Wrapper>
      Install Cleanah on your iPhone, tap
      <ShareIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
        <path d="M16 2v7h-2v-5h-12v16h12v-5h2v7h-16v-20h16zm2 9v-4l6 5-6 5v-4h-10v-2h10z" />
      </ShareIcon>
      and then "Add to Home Screen"
    </Wrapper>
  )
}

export default InstallPwaIphoneSafariToast

