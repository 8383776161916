import { Wrapper } from "./styles"

interface InputLabelProps {
  children: React.ReactNode
}
const InputLabel: React.FC<InputLabelProps> = ({children}) => {
  return <Wrapper>{children}</Wrapper>
}

export default InputLabel
