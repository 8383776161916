import { Wrapper } from './styles'

interface ButtonProps {
  children: React.ReactNode
  onClick?: () => void
  big?: boolean
  small?: boolean
  disabled?: boolean
  secondary?: boolean
  type?: 'submit' | 'reset' | 'button' | undefined
}

const Button: React.FC<ButtonProps> = ({ children, onClick, big = false, small = false, disabled = false, secondary = false, type = 'submit'}) => {
  return (
    <Wrapper onClick={onClick} big={big} small={small} disabled={disabled} secondary={secondary} type={type}>
      {children}
    </Wrapper>
  )
}

export default Button
