import { forwardRef } from 'react'
import { Wrapper } from './styles'

interface TextAreaProps {
  value: string
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void
  name?: string
  id?: string
  maxlength?: number
}

const TextArea: React.FC<TextAreaProps> = forwardRef(
  ({ name, value, onChange, id, maxlength }, ref: React.ForwardedRef<HTMLInputElement>) => {
    return <Wrapper name={name} value={value} onChange={onChange} id={id} maxLength={maxlength} />
  }
)

export default TextArea
