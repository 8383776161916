import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { useEffect, useState } from 'react'
import { Burger, Line, Logo, MenuItem, MenuItems, Wrapper } from './styles'
import { menuOpenState } from '../../atoms/MenuAtom'
import { useCurrentAuthenticatedUser } from '../../hooks/UserHook'

const Topbar = () => {
  const navigate = useNavigate()
  const setMenuOpen = useSetRecoilState(menuOpenState)
  const [loggedIn, setLoggedIn] = useState(false)
  const currentAuthenticatedUser = useCurrentAuthenticatedUser()

  useEffect(() => {
    currentAuthenticatedUser()
      .then(() => setLoggedIn(true))
      .catch(() => {
        // nothing
      })
  }, [currentAuthenticatedUser, setLoggedIn])

  return (
    <Wrapper>
      <Logo onClick={() => navigate('/')}>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.062 25.602L11.33 5.416C11.2683 5.30005 11.2301 5.17308 11.2175 5.04236C11.2049 4.91163 11.2182 4.77971 11.2566 4.65411C11.3341 4.40047 11.5093 4.18802 11.7434 4.0635C11.9776 3.93898 12.2517 3.91259 12.5053 3.99014C12.6309 4.02854 12.7477 4.0913 12.8491 4.17483C12.9504 4.25837 13.0343 4.36105 13.0959 4.477L23.829 24.663L25.351 23.853C25.8148 23.6064 26.3228 23.4536 26.8457 23.4033C27.3687 23.353 27.8964 23.4062 28.3988 23.5599C28.9011 23.7136 29.3683 23.9647 29.7736 24.299C30.1789 24.6332 30.5144 25.0441 30.761 25.508L31.409 26.727L38.2789 36.781L29.541 41.426L26.514 35.443L24.7299 36.346L27.775 42.366L24.0289 44.357L19.534 33.039L18.8869 31.821C18.6403 31.3572 18.4874 30.8493 18.4371 30.3264C18.3867 29.8035 18.4398 29.2758 18.5934 28.7734C18.747 28.271 18.998 27.8038 19.3322 27.3985C19.6664 26.9932 20.0771 26.6576 20.541 26.411L22.063 25.602H22.062ZM26.2889 25.62L21.479 28.177C21.247 28.3003 21.0417 28.4681 20.8746 28.6708C20.7075 28.8734 20.582 29.107 20.5052 29.3582C20.4284 29.6094 20.4018 29.8733 20.427 30.1347C20.4522 30.3962 20.5286 30.6501 20.652 30.882L21.299 32.1L29.642 27.664L28.9939 26.447C28.8707 26.2151 28.7029 26.0097 28.5002 25.8426C28.2975 25.6755 28.0639 25.55 27.8127 25.4732C27.5616 25.3964 27.2977 25.3699 27.0362 25.3951C26.7748 25.4202 26.5209 25.4967 26.2889 25.62Z"
            fill="#5C7AFF"
          />
          <path
            d="M17.768 35.36C17.5984 35.1562 17.3547 35.0282 17.0907 35.004C16.8267 34.9798 16.5638 35.0615 16.36 35.231H16.362L16.351 35.239L16.288 35.287C16.1985 35.3542 16.1071 35.4189 16.014 35.481C15.766 35.646 15.396 35.871 14.92 36.097C13.6965 36.6851 12.3575 36.9935 11 37C10.7348 37 10.4804 37.1054 10.2929 37.2929C10.1054 37.4804 10 37.7348 10 38C10 38.2652 10.1054 38.5196 10.2929 38.7071C10.4804 38.8946 10.7348 39 11 39C12.655 38.9936 14.2878 38.6189 15.78 37.903C16.2455 37.6826 16.6946 37.4293 17.124 37.145C17.2848 37.0375 17.4415 36.924 17.594 36.805L17.624 36.782L17.634 36.774L17.637 36.77H17.639L17.64 36.768C17.8436 36.5982 17.9715 36.3545 17.9955 36.0905C18.0195 35.8265 17.9377 35.5637 17.768 35.36ZM19.832 40.445C19.9049 40.5543 19.9556 40.6768 19.9811 40.8056C20.0066 40.9345 20.0065 41.0671 19.9808 41.1959C19.9551 41.3247 19.9042 41.4472 19.8312 41.5563C19.7581 41.6654 19.6643 41.7591 19.555 41.832L19.553 41.834L19.549 41.836L19.539 41.842L19.508 41.862C19.3349 41.9721 19.1591 42.0782 18.981 42.18C18.63 42.381 18.131 42.648 17.531 42.914C16.351 43.439 14.701 44 13 44C12.7348 44 12.4804 43.8946 12.2929 43.7071C12.1054 43.5196 12 43.2652 12 43C12 42.7348 12.1054 42.4804 12.2929 42.2929C12.4804 42.1054 12.7348 42 13 42C14.298 42 15.65 41.561 16.719 41.086C17.2759 40.8375 17.8164 40.5536 18.337 40.236C18.3662 40.2188 18.3948 40.2008 18.423 40.182L18.443 40.17L18.445 40.168C18.5543 40.0951 18.6768 40.0444 18.8056 40.0189C18.9345 39.9934 19.0671 39.9935 19.1959 40.0192C19.3247 40.0449 19.4471 40.0958 19.5563 40.1688C19.6654 40.2419 19.7591 40.3357 19.832 40.445Z"
            fill="#5C7AFF"
          />
        </svg>
        cleanah
      </Logo>
      <Burger onClick={() => setMenuOpen(true)} data-testid="burger">
        <Line />
        <Line />
        <Line />
      </Burger>
      <MenuItems>
        <MenuItem to="/result">
          Find cleaners
        </MenuItem>
        {!loggedIn && (
          <MenuItem to="/create-account">
            Join as a cleaner
          </MenuItem>
        )}
        <MenuItem to="/account">
          {loggedIn ? 'My account' : 'Login'}
        </MenuItem>
        <MenuItem to="/how-it-works">
          How it works
        </MenuItem>
      </MenuItems>
    </Wrapper>
  )
}

export default Topbar
