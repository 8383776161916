import { Wrapper } from "./styles"

interface FieldProps {
  children: React.ReactNode
}

const Field: React.FC<FieldProps> = ({children}) => {
  return <Wrapper>{children}</Wrapper>
}

export default Field
