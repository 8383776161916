import { AnalyticsEvent } from "../types"

export const sendAnalyticsEvent = (eventName: AnalyticsEvent, eventParams: {[key: string]: any}) => {
  if (process.env.REACT_APP_SEND_GA_EVENT !== 'true') return

  try {
    // @ts-ignore
    gtag('event', eventName, eventParams)
  } catch (e) {
    console.log('Error sending event', e)
  }
}
