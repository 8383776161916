import styled from "styled-components"

export const Wrapper = styled.div`
  line-height: 20px;
`
export const ShareIcon = styled.svg`
  transform: rotate(-90deg);
  fill: var(--blue);
  margin: 0 4px;
`
