export const useSendEmailTo = () => {
  const sendEmailTo = async (userName: string, userEmailOrMobile: string, userMessage: string, cleanerEmail: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SEND_EMAIL}`, {
        method: 'POST',
        body: JSON.stringify({userName, userEmailOrMobile, userMessage, cleanerEmail})
      })
      if (!response.ok) throw new Error(response.statusText)
      return true
    } catch (e) {
      console.log('Error sending email', e)
      return false
    }
  }

  return sendEmailTo
}
