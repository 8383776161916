import { useFormik } from 'formik'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { object, string } from 'yup'
import { sendMessagePopupState } from '../../atoms/SendMessagePopupAtom'
import { sendAnalyticsEvent } from '../../helpers/AnalyticsHelper'
import { displayNotification } from '../../helpers/NotificationHelper'
import { useSendEmailTo } from '../../hooks/EmailHook'
import Button from '../Button'
import ButtonWrapper from '../ButtonWrapper'
import Field from '../Field'
import InputError from '../InputError'
import InputLabel from '../InputLabel'
import Spinner from '../Spinner'
import TextArea from '../TextArea'
import TextInput from '../TextInput'
import { CleanerName, CleanerRate, CloseIcon, Container, Flex, Title, Topbar, Wrapper } from './styles'

const SendMessagePopup = () => {
  const [loading, setLoading] = useState(false)
  const sendEmailTo = useSendEmailTo()
  const [sendMessagePopup, setSendMessagePopup] = useRecoilState(sendMessagePopupState)
  const formik = useFormik({
    initialValues: {
      name: '',
      emailOrMobile: '',
      message: '',
    },
    validationSchema: object({
      name: string().required('Name is required.'),
      emailOrMobile: string().required('Email or mobile is required.'),
      message: string().required('Message is required.'),
    }),
    onSubmit: async ({ name, emailOrMobile, message }) => {
      const { email, name: cleanerName, rate } = sendMessagePopup
      setLoading(true)
      const response = await sendEmailTo(name, emailOrMobile, message, email)
      if (!response) {
        setLoading(false)
        displayNotification('Error sending message. Try again later.')
        sendAnalyticsEvent('send_message_error', { name: cleanerName, rate, email })
        return
      }

      setLoading(false)
      setSendMessagePopup({ ...sendMessagePopup, isOpen: false })
      formik.resetForm()
      displayNotification('Your message has been sent 😃')
      sendAnalyticsEvent('send_message', { name: cleanerName, rate, email })
    },
  })

  const handleClosePopup = () => {
    setSendMessagePopup({ ...sendMessagePopup, isOpen: false })
    formik.resetForm()
  }

  if (!sendMessagePopup.isOpen) return null

  return (
    <Wrapper>
      <Topbar>
        <CloseIcon
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          data-testid="close-send-message"
          fill="darkgrey"
          onClick={handleClosePopup}
        >
          <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
        </CloseIcon>
      </Topbar>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Title>Sending message to</Title>
          <Flex style={{ marginTop: '20px', marginBottom: '40px' }}>
            <CleanerName>{sendMessagePopup.name}</CleanerName>
            {sendMessagePopup.rate > 0 && <CleanerRate>${sendMessagePopup.rate}/h</CleanerRate>}
          </Flex>
          <Field>
            <InputLabel>Your name</InputLabel>
            <TextInput name="name" value={formik.values.name} onChange={formik.handleChange} autofocus />
            {formik.touched.name && formik.errors.name && <InputError>{formik.errors.name}</InputError>}
          </Field>
          <Field>
            <InputLabel>
              Your email or mobile <span>so cleaner can reply</span>
            </InputLabel>
            <TextInput name="emailOrMobile" value={formik.values.emailOrMobile} onChange={formik.handleChange} />
            {formik.touched.emailOrMobile && formik.errors.emailOrMobile && (
              <InputError>{formik.errors.emailOrMobile}</InputError>
            )}
          </Field>
          <Field>
            <InputLabel>Your message</InputLabel>
            <TextArea name="message" value={formik.values.message} onChange={formik.handleChange} maxlength={500} />
            {formik.touched.message && formik.errors.message && <InputError>{formik.errors.message}</InputError>}
          </Field>
          <ButtonWrapper>
            <Button disabled={loading}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white">
                <path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z" />
              </svg>
              Send message
            </Button>
            <Button secondary onClick={handleClosePopup}>
              Cancel
            </Button>
          </ButtonWrapper>
          {loading && (
            <div style={{ marginTop: '20px' }}>
              <Spinner />
            </div>
          )}
        </form>
      </Container>
    </Wrapper>
  )
}

export default SendMessagePopup
