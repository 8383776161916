import { atom } from 'recoil'

export const sendMessagePopupState = atom({
  key: 'sendMessagePopupState',
  default: {
    isOpen: false,
    name: '',
    rate: 0,
    email: '',
  },
})
