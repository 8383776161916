import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Topbar from './components/Topbar'
import Footer from './components/Footer'
import Menu from './components/Menu'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Scroller from './components/Scroller'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import { RecoilRoot } from 'recoil'
import PrivateRoute from './components/PrivateRoute'
import { bindBeforeInstallPrompt } from './helpers/PwaHelper'
import SendMessagePopup from './components/SendMessagePopup'

Amplify.configure(awsconfig)

const Container = styled.div`
  overflow-x: hidden;
  position: relative;
`

const HomePage = React.lazy(() => import('./pages/HomePage'))
const AddressPage = React.lazy(() => import('./pages/AddressPage'))
const ResultPage = React.lazy(() => import('./pages/ResultPage'))
const HowItWorksPage = React.lazy(() => import('./pages/HowItWorksPage'))
const CreateAccountPage = React.lazy(() => import('./pages/CreateAccountPage'))
const VerifyAccountPage = React.lazy(() => import('./pages/VerifyAccountPage'))
const LoginPage = React.lazy(() => import('./pages/LoginPage'))
const LogoutPage = React.lazy(() => import('./pages/LogoutPage'))
const AccountPage = React.lazy(() => import('./pages/AccountPage'))
const FreeAccountSignupPage = React.lazy(() => import('./pages/FreeAccountSignupPage'))
const UpgradePlanPage = React.lazy(() => import('./pages/UpgradePlanPage'))
const ForgotPasswordPage = React.lazy(() => import('./pages/ForgotPasswordPage'))
const NewPasswordPage = React.lazy(() => import('./pages/NewPasswordPage'))
const PrivacyPolicyPage = React.lazy(() => import('./pages/PrivacyPolicyPage'))
const TermsAndConditionsPage = React.lazy(() => import('./pages/TermsAndConditionsPage'))
const HowToKeepYourHouseCleanPage = React.lazy(() => import('./pages/HowToKeepYourHouseCleanPage'))

const App = () => {
  useEffect(() => {
    bindBeforeInstallPrompt()
  }, [])

  return (
    <RecoilRoot>
      <Container>
        <Router>
          <Scroller />
          <Topbar />
          <Menu />
          <SendMessagePopup />
          <Routes>
            <Route
              index
              element={
                <React.Suspense fallback={<>...</>}>
                  <HomePage />
                </React.Suspense>
              }
            />
            <Route
              path="/address"
              element={
                <React.Suspense fallback={<>...</>}>
                  <AddressPage />
                </React.Suspense>
              }
            />
            <Route
              path="/result/:latitude/:longitude"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ResultPage />
                </React.Suspense>
              }
            />
            <Route
              path="/result"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ResultPage />
                </React.Suspense>
              }
            />
            <Route
              path="/how-it-works"
              element={
                <React.Suspense fallback={<>...</>}>
                  <HowItWorksPage />
                </React.Suspense>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <React.Suspense fallback={<>...</>}>
                  <PrivacyPolicyPage />
                </React.Suspense>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <React.Suspense fallback={<>...</>}>
                  <TermsAndConditionsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/create-account"
              element={
                <React.Suspense fallback={<>...</>}>
                  <CreateAccountPage />
                </React.Suspense>
              }
            />
            <Route
              path="/verify-account"
              element={
                <React.Suspense fallback={<>...</>}>
                  <VerifyAccountPage />
                </React.Suspense>
              }
            />
            <Route
              path="/account"
              element={
                <React.Suspense fallback={<>...</>}>
                  <PrivateRoute>
                    <AccountPage />
                  </PrivateRoute>
                </React.Suspense>
              }
            />
            <Route
              path="/account/free"
              element={
                <React.Suspense fallback={<>...</>}>
                  <PrivateRoute>
                    <FreeAccountSignupPage />
                  </PrivateRoute>
                </React.Suspense>
              }
            />
            <Route
              path="/account/upgrade"
              element={
                <React.Suspense fallback={<>...</>}>
                  <PrivateRoute>
                    <UpgradePlanPage />
                  </PrivateRoute>
                </React.Suspense>
              }
            />
            <Route
              path="/login"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LoginPage />
                </React.Suspense>
              }
            />
            <Route
              path="/logout"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LogoutPage />
                </React.Suspense>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ForgotPasswordPage />
                </React.Suspense>
              }
            />
            <Route
              path="/new-password"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NewPasswordPage />
                </React.Suspense>
              }
            />
            <Route
              path="/how-to-keep-your-house-clean"
              element={
                <React.Suspense fallback={<>...</>}>
                  <HowToKeepYourHouseCleanPage />
                </React.Suspense>
              }
            />
            <Route
              path="*"
              element={
                <React.Suspense fallback={<>...</>}>
                  <HomePage />
                </React.Suspense>
              }
            />
          </Routes>
          <Footer />
        </Router>
      </Container>
    </RecoilRoot>
  )
}

export default App
