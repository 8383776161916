import styled from 'styled-components'

export const Wrapper = styled.label`
  font-size: 14px;
  color: grey;
  margin-bottom: 5px;
  display: block;

  & span {
    color: lightgrey;
    font-size: 14px;
  }
`
