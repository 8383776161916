import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background: whitesmoke;
  padding: 60px 20px;
  color: darkgrey;
  font-size: 14px;
  position: relative;

  @media (min-width: 576px) {
    padding: 60px;
  }
`
export const Links = styled.div``
export const Link = styled(NavLink)`
  text-decoration: underline;
  margin-top: 10px;
  display: block;
  color: darkgrey;

  &:first-child {
    margin-top: 0;
  }
`
export const Support = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;

  & > svg {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    position: relative;
    top: 2px;
  }
`
export const Copy = styled.div`
  color: lightgrey;
  font-size: 13px;
  position: absolute;
  bottom: 20px;
  left: 20px;

  @media (min-width: 576px) {
    left: 60px;
  }
`
export const Icon = styled.svg`
  fill: white;
  position: absolute;
  top: 60px;
  right: 20px;
  width: 120px;
  height: 120px;
  
  @media (min-width: 576px) {
    right: 60px;
  }
`
