export const isPremium = (user: any) => {
  const { attributes } = user
  const { 'custom:planExpiryDate': planExpiryDate } = attributes

  if (!planExpiryDate) return false
  return new Date(planExpiryDate).getTime() > new Date().getTime()
}

export const hasSignupPlan = (user: any) => {
  const { attributes } = user
  const {
    'custom:name': name,
    'custom:rate': rate,
    'custom:suburb': suburb,
    'custom:latitude': latitude,
    'custom:longitude': longitude,
  } = attributes

  return name && rate && suburb && latitude && longitude
}
