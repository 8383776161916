import InstallPwaIphoneSafariToast from '../components/InstallPwaIphoneSafariToast'
import { displayNotification } from './NotificationHelper'

let deferredPrompt: any
const pwaMenuItemId = 'pwa-menu-item'

// TODO remove console.log
export const bindBeforeInstallPrompt = () => {
  const pwaMenuItem = document.querySelector<HTMLDivElement>(`#${pwaMenuItemId}`)
  if (!pwaMenuItem) {
    console.log('PWA menu item does not exist')
    return
  }

  pwaMenuItem.addEventListener('click', async () => {
    if (!deferredPrompt) {
      console.log('Deferred prompt is falsy')
      return
    }

    deferredPrompt.prompt()
    const { outcome } = await deferredPrompt.userChoice
    if (outcome === 'accepted') {
      console.log('User installed app')
      pwaMenuItem.style.display = 'none'
    } else {
      console.log('User did not install app')
    }
  })

  window.addEventListener('beforeinstallprompt', (e) => {
    console.log('beforeinstallprompt')
    deferredPrompt = e
    pwaMenuItem.style.display = 'block'
  })
}

export const displayIosInstallAppInstructions = (userAgent: string) => {
  if (
    isIosPwaEnabled() &&
    !isInStandaloneMode() &&
    isSafariOnIphone(userAgent) &&
    !hasIosInstallAppInstructionsShownUp()
  ) {
    displayNotification(<InstallPwaIphoneSafariToast />, { duration: null, position: 'bottom' })
    iosInstallAppInstructionsHasShownUp()
    return true
  }

  return false
}

export const isIosPwaEnabled = () => process.env.REACT_APP_PWA_IOS === 'true'
export const hasIosInstallAppInstructionsShownUp = () =>
  localStorage.getItem('iosInstallAppInstructionsShownUp') === 'true'
// @ts-ignore
export const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone
export const isSafariOnIphone = (userAgent: string) =>
  /iP(ad|od|hone)/i.test(userAgent) && /WebKit/i.test(userAgent) && !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)
export const iosInstallAppInstructionsHasShownUp = () =>
  localStorage.setItem('iosInstallAppInstructionsShownUp', 'true')
