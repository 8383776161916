import styled from 'styled-components'

interface WrapperProps {
  marginTop?: string
}
export const Wrapper = styled.div<WrapperProps>`
  display: flex;

  & > *:first-child {
    margin-right: 40px;
  }

  ${({marginTop}) => marginTop && `
    margin-top: ${marginTop};
  `}
`
