import styled from 'styled-components'

export const Wrapper = styled.textarea`
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  color: #444;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
`
