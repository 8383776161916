import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: relative;
  
  @media (min-width: 576px) {
    padding: 10px 60px;
  }
`
export const Logo = styled.div`
  display: flex;
  align-items: end;
  font-size: 22px;
  color: var(--blue);
  font-weight: 700;
  cursor: pointer;

  & > svg {
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }
`
export const Burger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
`
export const Line = styled.div`
  background: darkgrey;
  height: 3px;
  border-radius: 5px;
  margin-top: 5px;

  &:first-child {
    margin-top: 0;
    width: 20px;
  }

  &:nth-child(2) {
    width: 16px;
  }

  &:nth-child(3) {
    width: 12px;
  }
`
export const MenuItems = styled.nav`
  display: none;

  & a:not(:last-child) {
    margin-right: 40px;
  }

  @media (min-width: 768px) {
    display: flex;
  }
`
export const MenuItem = styled(Link)`
  color: darkgrey;
  font-size: 14px;
  display: flex;
  align-items: center;
`
