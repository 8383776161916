import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: calc(100vw);
  height: 100vh;
  background: white;
`
export const Topbar = styled.div`
display: flex;
justify-content: flex-end;
border-bottom: 1px solid whitesmoke;
`
export const CloseIcon = styled.svg`
  padding: 20px;
  cursor: pointer;
`
export const Container = styled.div`
  padding: 40px 20px 60px 20px;
  max-width: 700px;
  margin: 0 auto;
`
export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
`
export const Flex = styled.div`
  display: flex;
`
export const CleanerName = styled.h3`
  font-size: 20px;
  color: var(--blue);
`
export const CleanerRate = styled.h3`
  font-size: 20px;
  color: darkgrey;
  margin-left: 12px;

`
