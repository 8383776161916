import styled from 'styled-components'

interface WrapperProps {
  big: boolean
  small: boolean
  disabled: boolean
  secondary: boolean
}
export const Wrapper = styled.button<WrapperProps>`
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  background: var(--blue);
  color: white;
  font-weight: 700;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid var(--blue);
  box-shadow: 0 10px 10px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 6px;
  }

  ${({ big }) =>
    big &&
    `
    padding: 20px 40px;
    font-size: 24px;
  `}

  ${({ small }) =>
    small &&
    `
    font-weight: normal;
    font-size: 12px;
    padding: 8px 12px;
  `}

  ${({ secondary }) =>
    secondary &&
    `
    background: white;
    color: var(--blue);
    border: 1px solid var(--blue);
    box-shadow: none;    
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background: lightgrey;
    color: white;
    border: 1px solid lightgrey;
    box-shadow: none;
  `}
`
