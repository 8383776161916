import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentAuthenticatedUser } from '../../hooks/UserHook'
import Spinner from '../Spinner'
import { SpinnerContainer } from './styles'

interface PrivateRouteProps {
  children: React.ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState<Boolean>()
  const navigate = useNavigate()
  const currentAuthenticatedUser = useCurrentAuthenticatedUser()

  useEffect(() => {
    currentAuthenticatedUser()
      .then(() => setLoggedIn(true))
      .catch(() => navigate('/login'))
  }, [navigate, currentAuthenticatedUser])

  if (loggedIn === undefined) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (loggedIn) return <>{children}</>

  return null
}

export default PrivateRoute
